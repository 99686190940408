@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@import '~booksprout-app/src/css/functions/rem-fn'
@import '~booksprout-app/src/css/mixins/media'

.q-dialog__inner--minimized > .bs-demo-dialog
  max-inline-size: rem-fn(640)

.bs-demo-dialog
  background-color: #fff
  padding: rem-fn(20)
  position: relative

  .bs-modal__footer
    .bs-btn
      max-inline-size: 100%

  @include from($breakpoint-sm-min)
    padding-block: rem-fn(50)
    padding-inline: rem-fn(60)

.bs-d-d-logo
  border-radius: 50%
  background: #D8FFDE
  width: rem-fn(146)
  height: rem-fn(146)
  display: flex
  align-items: center
  justify-content: center
  margin-block-end: rem-fn(30)
  margin-inline: auto

.bs-d-d-content
  text-align: center

  h2
    margin-inline: auto
    margin-block-end: rem-fn(20)
    max-inline-size: rem-fn(330)

  p
    margin-inline: auto
    margin-block-end: rem-fn(30)
    max-inline-size: rem-fn(468)

.bs-d-d-block
  background-color: $bs-light-grey-background
  border: rem-fn(1) solid $bs-light-grey-outline
  border-radius: $bs-border-radius-sm
  color: #000
  display: flex
  flex-flow: column
  margin-block-end: rem-fn(20)
  padding: rem-fn(30)
  row-gap: rem-fn(10)
  text-align: left

.bs-d-d-b-buttons,
.bs-d-d-b-images
  column-gap: rem-fn(20)
  display: flex
  margin-block-start: rem-fn(5)
